import { module } from "modujs";
import { debounce, throttle } from "../utils/tickers";

export default class extends module {

  constructor(m){
    super(m);

    this.onResizeBind = this.onResize.bind(this);

    this.$el = this.el;
    this.$items = this.$('item');

    this.size = {
      mobile: 500,
      desktop: 1000
    }
  }

  init(){

    this.onResize();
    this.bindEvents();
   

  }


  bindEvents(){
    window.addEventListener('resize', debounce(this.onResizeBind, 1000));
  }

  unbindEvents(){
    window.removeEventListener('resize', this.onResizeBind);
  }

  onResize(){
    this.$items.forEach((el) => {
      const x = el.getBoundingClientRect().x;

      if(x === 0){
        el.classList.add('is-edge');
      }
      else{
        el.classList.remove('is-edge');
      }
    })
   
  }

  destoy(){
    super.destroy();
    this.unbindEvents();
  }
}